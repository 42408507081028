import React, { useEffect } from 'react';
import {
  Button,
  FormControl,
  FormLabel,
  Input,
  Stack,
  useToast,
  Center,
  FormErrorMessage,
} from '@chakra-ui/react';
import { useApiMutation, useApiQuery } from '../../hooks/api-communication';
import { uploadArtwork } from '../../api/controllers/campaign/uploadArtwork';
import { useQueryClient } from '@tanstack/react-query';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { useTypedForm } from '../../hooks/useTypedForm';
import { z } from 'zod';
import { AddIcon } from '@chakra-ui/icons';
import { getArtworkById } from '../../api/controllers/campaign/getArtworkById';
import { useMe } from '../../hooks/useMe';
import { updateArtwork } from '../../api/controllers/campaign/updateArtwork';

export const ArtworkFormPage: React.FC = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const toast = useToast();
  const { isRole, isLoading: isLoadingMe } = useMe();
  const { campaignId, artworkId } = useParams();
  const isEditMode = artworkId !== undefined;
  const formSchema = z.object({
    dimensions: z.object({
      width: z.number().positive(),
      height: z.number().positive(),
      x: z.number(),
      y: z.number(),
    }),
    file: isEditMode ? z.any() : z.custom<File>((v) => v instanceof File, {
      message: 'Please select a file',
    }),
  });
  type FormValues = z.infer<typeof formSchema>;
  const form = useTypedForm({
		schema: formSchema,
	});

  const { data, isLoading: isLoadingArtwork } = useApiQuery(getArtworkById, ['artwork', artworkId], {
		variables: { routeParams: { campaignId: campaignId ?? '', artworkId: artworkId ?? '' } },
    enabled: isEditMode,
	});

  const setDefaultValues = () => {
		const defaultValues = {
			dimensions: {
        width: data?.dimensions.width,
        height: data?.dimensions.height,
        x: data?.dimensions.x,
        y: data?.dimensions.y,
      },
		};
		form.reset(defaultValues);
	};

	useEffect(() => {
		if (!isLoadingArtwork) {
			setDefaultValues();
		}
	}, [isLoadingArtwork]);
  
  const { mutateAsync: uploadArtworkMutation } = useApiMutation(uploadArtwork, {
    onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['artwork', campaignId] });
		},
	});

  const { mutateAsync: updateArtworkMutation } = useApiMutation(updateArtwork, {
    onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['artwork', artworkId] });
		},
  });

  const onSubmit = async (values: FormValues) => {
    console.log('odeslano');
    const body = { ...values };

    console.log(body);
    if (isEditMode) {
      await updateArtworkMutation({ 
        routeParams: {
          campaignId: campaignId ?? '',
          artworkId: artworkId ?? '',
        },
        body
      });
    } else {
      await uploadArtworkMutation({ 
        file: body.file, 
        campaignId: campaignId ?? '', 
        dimensions: body.dimensions
      });
    }
    
    toast({
      title: "Artwork has been uploaded",
      status: "success",
      duration: 3000,
      isClosable: true,
    });
    navigate(`/campaigns/${campaignId}`);
 };

	return (
		<Stack backgroundColor={'white'} p={5}>
      <form noValidate onSubmit={form.handleSubmit(onSubmit)}>
        <Stack gap={4} py={8}>
        <FormControl isInvalid={Boolean(form.formState.errors.dimensions?.width?.message)} isRequired>
          <FormLabel>Width</FormLabel>
          <Input
            variant={'outline'}
            borderColor={'gray.400'}
            type="number"
            {...form.register('dimensions.width', { valueAsNumber: true })}
          />
            {form.formState.errors.dimensions?.width && (
            <FormErrorMessage>{form.formState.errors.dimensions.width?.message}</FormErrorMessage>
          )}
        </FormControl>
        
        <FormControl isInvalid={Boolean(form.formState.errors.dimensions?.height?.message)} isRequired>
          <FormLabel>Height</FormLabel>
          <Input
            variant={'outline'}
            borderColor={'gray.400'}
            type="number"
              {...form.register('dimensions.height', { valueAsNumber: true })}
          />
          {form.formState.errors.dimensions?.height && (
            <FormErrorMessage>{form.formState.errors.dimensions?.height?.message}</FormErrorMessage>
          )}
        </FormControl>
        
        <FormControl isInvalid={Boolean(form.formState.errors.dimensions?.x?.message)} isRequired>
          <FormLabel>X Location</FormLabel>
          <Input
            variant={'outline'}
            borderColor={'gray.400'}
            type="number"
              {...form.register('dimensions.x', { valueAsNumber: true })}
          />
          {form.formState.errors.dimensions?.x && (
            <FormErrorMessage>{form.formState.errors.dimensions?.x?.message}</FormErrorMessage>
          )}
        </FormControl>
        
        <FormControl isInvalid={Boolean(form.formState.errors.dimensions?.y?.message)} isRequired>
          <FormLabel>Y Location</FormLabel>
          <Input
            variant={'outline'}
            borderColor={'gray.400'}
            type="number"
            {...form.register('dimensions.y', { valueAsNumber: true })}
          />
            {form.formState.errors.dimensions?.y && (
            <FormErrorMessage>{form.formState.errors.dimensions?.y?.message}</FormErrorMessage>
          )}
        </FormControl>
        <FormControl>
          {!isEditMode && (
            <FormLabel htmlFor={'file'}>
              <Center p={3} borderRadius={4} border={'1px solid'} borderColor={'primary'}>
                <AddIcon />
                &nbsp; Add Artwork
              </Center>
              <Input
                type={'file'}
                id={'file'}
                display={'none'}
                accept='image/*,video/*'
                {...form.register('file', {
                  onChange: ({ target }) => {
                    if (target.files && target.files.length > 0) {
                      form.setValue('file', target.files[0]);
                    }
                  }
                })}
              />
            </FormLabel>
          )}
        </FormControl>
								
          <Button type="submit" colorScheme="blue">
            {isEditMode ? 'Edit Artwork' : 'Upload Artwork'}
          </Button>
        </Stack>
      </form>
    </Stack>
  );
};
