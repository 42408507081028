import { Box, Center, Heading, Stack, Text } from '@chakra-ui/react';
import { FC, PropsWithChildren } from 'react';
import { FeatureCampaign } from '../components/Homepage/FeatureCampaign';
import { Claims } from '../components/Homepage/Claims';
import { Market } from '../components/Homepage/Market';
import { Potential } from '../components/Homepage/Potential';
import { Solution } from '../components/Homepage/Solution';
import { ContactUs } from '../components/Homepage/ContactUs';
import { Footer } from '../components/Homepage/Footer';

export const Homepage: FC = () => {
	return (
		<Stack>
			<Center w={'100%'} height={'80svh'} backgroundColor={'gray.50'} px={{ base: '24px', md: '48px', xl: '80px' }}>
				<Stack alignItems={'center'} w={'100%'}>
					<Heading size={'4xl'} fontWeight={'bold'} textAlign={'center'} color={'gray.800'}>
						Elevate your 💣 business with
					</Heading>
					<Heading size={'4xl'} fontWeight={'bold'} textAlign={'center'} color={'gray.400'}>
						Ad serving platform
					</Heading>
				</Stack>
			</Center>
			<FeatureCampaign />
			<Claims />
			<Market />
			<Potential />
			<Solution />
			<ContactUs />
			<Footer />
		</Stack>
	);
};
