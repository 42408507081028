import { Box } from '@chakra-ui/react';
import { BannerProps } from './types';

export const ImageBanner: React.FC<BannerProps> = ({ url, width, height, x, y}) => {
	return (
		<Box sx={{ img: { width: width, height: height, objectFit: 'contain', position: 'fixed', left: x, top: y } }}>
			<img src={url} width={width} height={height} alt="" />
		</Box>
	);
};

export {};
