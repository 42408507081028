import { z } from 'zod';
import { callApi } from '../../base';

const response = z.object({
	id: z.string(),
	uploadUrl: z.string(),
	formDataFields: z.array(z.any()),
});

type Request = Readonly<{
	campaignId: string;
	file: File;
	dimensions: {
		width: number;
		height: number;
		x: number;
		y: number;
	};
}>;

export const uploadArtwork = async ({ file, campaignId, dimensions }: Request) => {
	const { uploadUrl, formDataFields } = await callApi(`/campaigns/${campaignId}/artworks`, response, {
		method: 'POST',
		credentials: 'include',
		body: JSON.stringify({
			'dimensions': dimensions
		})
	});
	const formData = new FormData();
	//@ts-expect-error
	formDataFields.map(({ key, value }) => formData.set(key, value));
	formData.append('file', file);
	await fetch(uploadUrl, {
		method: 'POST',
		body: formData,
	});
};
