import { FC, ReactNode } from 'react'
import { useAuth } from '../hooks/useAuth'
import { Navigate } from 'react-router-dom'

export const AuthGuard: FC<{ children: ReactNode }> = ({ children }) => {
  const { isSignedIn } = useAuth()

  if (!isSignedIn) {
    return <Navigate to='/signin' replace />
  }

  return children
}
