import { extendTheme } from '@chakra-ui/react';

export const theme = (isInStreamingSoftware: boolean) =>
	extendTheme({
		styles: {
			global: {
				'html, body': {
					boxSizing: 'border-box',
					padding: 0,
					margin: 0,
					background: 'transparent',
					overflow: isInStreamingSoftware ? 'hidden' : 'auto',
					color: 'black',
				},
				a: {
					color: 'teal.500',
				},
			},
		},
	});
