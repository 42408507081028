import { callApi } from '../../base';

type Params = Readonly<{
	campaignId: string;
}>;

export const unsubscribeUserCampaign = async (params: Params): Promise<null> =>
	callApi(`/campaigns/${params.campaignId}/subscription`, null, {
		method: 'DELETE',
		credentials: 'include',
	});
