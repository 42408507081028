import { useCallback, useEffect, useRef, useState } from 'react';

export const baseWsUrl = import.meta.env.VITE_WS_URL as string;
const RECONNECT_INTERVAL = 30000; // 30 seconds

const getWsUrl = (token: string): URL => {
	const url = new URL(baseWsUrl);
	url.searchParams.append('token', token);
	return url;
};

const createWs = (token: string) => {
	console.log('Creating socket');
	return new WebSocket(getWsUrl(token));
};

const useSocket = (token: string | null) => {
	const [socket, setSocket] = useState<WebSocket>();
	const [isVisible, setIsVisible] = useState<boolean>(document.visibilityState === 'visible');

	const visibilityChange = useCallback(() => {
		setIsVisible(document.visibilityState === 'visible');
	}, []);

	const cleanupSocket = useCallback((socket?: WebSocket) => {
		if (socket != null) {
			socket.close();
			setSocket(undefined);
		}
	}, []);

	useEffect(() => {
		document.addEventListener('visibilitychange', visibilityChange);

		if (token != null) {
			if (isVisible && socket == null) {
				const ws = createWs(token);
				ws.onclose = () => {
					console.log('Socket closed');
					// hack for reconnect
					if (isVisible) {
						setIsVisible(false);

						setTimeout(() => {
							setIsVisible(document.visibilityState === 'visible');
						}, RECONNECT_INTERVAL);
					};
				};

				setSocket(ws);
			} else if (!isVisible && socket != null) {
				cleanupSocket(socket);
			}
		}

		return () => {
			cleanupSocket(socket);
			document.removeEventListener('visibilitychange', visibilityChange);
		};
	}, [token, isVisible, socket, cleanupSocket, visibilityChange]);

	return socket;
};

export {
	useSocket,
};
