import { FC } from 'react';
import { ContentBlock } from './ContentBlock';
import { Box, Center, Heading, Stack, Text } from '@chakra-ui/react';

export const FeatureCampaign: FC = () => (
	<ContentBlock>
		<Center backgroundColor={'gray.50'} flexDirection={'column'} p={3} gap={2} borderRadius={12} w={'100%'}>
			<Heading size={'lg'} fontWeight={'bold'} color={'gray.800'} py={5}>
				Featured campaign
			</Heading>
			<Stack
				p={3}
				flexDirection={{ base: 'column', md: 'row' }}
				alignItems={'stretch'}
				justifyContent={'space-between'}
				bgColor={'white'}
				borderRadius={8}
				w={'100%'}
				gap={{ base: 3, md: 4, xl: 8 }}
			>
				<Box
					flex={{ md: '1 2 0' }}
					borderRadius={6}
					bgSize={'cover'}
					height={{ base: 240, md: 'unset' }}
					bgImage={'url(https://via.placeholder.com/400x300)'}
					backgroundPosition={'center'}
				/>
				<Box flex={'2 1 0'} py={5}>
					<Stack gap={5}>
						<Stack>
							<Text>
								<strong>Up to 11 EUR</strong> / 1000 views
							</Text>
							<Heading fontWeight={400}>
								<strong>Disney+</strong>&nbsp; Agatha All Along
							</Heading>
						</Stack>
						<Stack flexDir={'row'} gap={2}>
							<Box bgColor={'gray.200'} borderRadius={8} w={9} h={9} />
							<Box bgColor={'gray.200'} borderRadius={8} w={9} h={9} />
							<Box bgColor={'gray.200'} borderRadius={8} w={9} h={9} />
						</Stack>
						<Text>
							Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nam dapibus, nunc vel placerat dictum, libero urna euismod sapien,
							consequat venenatis nibh sem sit amet risus.
						</Text>
					</Stack>
				</Box>
			</Stack>
		</Center>
	</ContentBlock>
);
