import { z } from 'zod';
import { callApi } from '../../base';

type RouteParams = Readonly<{
	campaignId: string;
	artworkId: string;
}>;

type Request = Readonly<{
	routeParams: RouteParams;
}>;

const response = z.object({
	id: z.string().uuid(),
	type: z.enum(['image', 'video', 'unknown']),
	status: z.enum(['deleted', 'error', 'pending', 'uploaded']),
	url: z.string(),
	dimensions: z.object({
		width: z.number().positive(),
		height: z.number().positive(),
		x: z.number(),
		y: z.number(),
	}),
});

export const getArtworkById = async ({ routeParams: { campaignId, artworkId } }: Request): Promise<z.infer<typeof response>> => {
	return callApi(`/campaigns/${campaignId}/artworks/${artworkId}`, response, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
		},
		credentials: 'include',
	});
};
