import {
	Button,
	Center,
	FormControl,
	FormErrorMessage,
	FormLabel,
	Grid,
	GridItem,
	Heading,
	Input,
	Stack,
	Textarea,
} from '@chakra-ui/react';
import { FC } from 'react';
import { ContentBlock } from './ContentBlock';
import { useTypedForm } from '../../hooks/useTypedForm';
import { z } from 'zod';

const createSchema = z.object({
	name: z.string(),
	email: z.string().email(),
	phone: z.string(),
	subject: z.string(),
	message: z.string(),
});

export const ContactUs: FC = () => {
	const form = useTypedForm({ schema: createSchema });

	return (
		<Center w={'100%'} backgroundColor={'gray.50'}>
			<ContentBlock>
				<Grid gridTemplateColumns={{ base: '1fr', md: '1fr 1fr' }} gap={4}>
					<GridItem w={'100%'}>
						<Heading size={'lg'}>Interested but don’t know where to start?</Heading>
					</GridItem>
					<GridItem w={'100%'}>
						<form noValidate>
							<Grid gridTemplateColumns={{ base: '1fr', md: '1fr 1fr' }} gap={4}>
								<GridItem w={'100%'}>
									<FormControl isInvalid={Boolean(form.formState.errors.name?.message)} isRequired>
										<FormLabel>Name</FormLabel>
										<Input variant={'outline'} borderColor={'gray.400'} {...form.register('name')} />
										{form.formState.errors.name && <FormErrorMessage>{form.formState.errors.name?.message}</FormErrorMessage>}
									</FormControl>
								</GridItem>
								<GridItem w={'100%'}>
									<FormControl isInvalid={Boolean(form.formState.errors.email?.message)} isRequired>
										<FormLabel>Email</FormLabel>
										<Input variant={'outline'} borderColor={'gray.400'} {...form.register('email')} />
										{form.formState.errors.email && <FormErrorMessage>{form.formState.errors.email?.message}</FormErrorMessage>}
									</FormControl>
								</GridItem>
								<GridItem w={'100%'}>
									<FormControl isInvalid={Boolean(form.formState.errors.phone?.message)} isRequired>
										<FormLabel>Phone</FormLabel>
										<Input variant={'outline'} borderColor={'gray.400'} type={'tel'} {...form.register('phone')} />
										{form.formState.errors.phone && <FormErrorMessage>{form.formState.errors.phone?.message}</FormErrorMessage>}
									</FormControl>
								</GridItem>
								<GridItem w={'100%'}>
									<FormControl isInvalid={Boolean(form.formState.errors.subject?.message)} isRequired>
										<FormLabel>Subject</FormLabel>
										<Input variant={'outline'} borderColor={'gray.400'} {...form.register('subject')} />
										{form.formState.errors.subject && <FormErrorMessage>{form.formState.errors.subject?.message}</FormErrorMessage>}
									</FormControl>
								</GridItem>
								<GridItem gridColumn={'1 / -1'}>
									<FormControl isInvalid={Boolean(form.formState.errors.subject?.message)} isRequired>
										<FormLabel>Subject</FormLabel>
										<Textarea variant={'outline'} borderColor={'gray.400'} {...form.register('subject')} />
										{form.formState.errors.subject && <FormErrorMessage>{form.formState.errors.subject?.message}</FormErrorMessage>}
									</FormControl>
								</GridItem>
								<GridItem gridColumn={'1 / -1'}>
									<Stack flexDir={'row'} justifyContent={'flex-end'}>
										<Button>Send message</Button>
									</Stack>
								</GridItem>
							</Grid>
						</form>
					</GridItem>
				</Grid>
			</ContentBlock>
		</Center>
	);
};
