import { useCookies } from 'react-cookie';
import { useApiQuery } from './api-communication';
import { getMe, Me } from '../api/controllers/auth/getMe';

const useMe = () => {
	const [{ sessionToken }] = useCookies(['sessionToken']);
	const { data, isLoading } = useApiQuery(getMe, ['auth', 'me'], { enabled: sessionToken != null });

	const isRole: Record<Me['user']['role'], boolean> = {
		streamer: data?.user.role === 'streamer',
		admin: data?.user.role === 'admin',
		campaigner: data?.user.role === 'campaigner',
	};

	return { me: data, isLoading, isRole };
};

export { useMe };
