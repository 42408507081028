import Router from './router/Router'
import { useAuth } from './hooks/useAuth'
import { useNavigate } from 'react-router-dom'
import { useEffect } from 'react'
function App() {
  const { isSignedIn } = useAuth()
  const navigate = useNavigate()

  useEffect(() => {
    if (isSignedIn && window.location.pathname === '/') {
      navigate('/campaigns', { replace: true })
    }
  }, [isSignedIn, navigate])

  return <Router />
}

export default App
