import {
	Box,
	Button,
	ButtonGroup,
	Card,
	CardBody,
	CardFooter,
	CardHeader,
	Center,
	FormLabel,
	Heading,
	IconButton,
	Input,
	Spinner,
	Stack,
	Text,
	useToast,
} from '@chakra-ui/react';
import { FC } from 'react';
import { Link, useParams } from 'react-router-dom';
import { useApiMutation, useApiQuery } from '../../hooks/api-communication';
import { getCampaignById } from '../../api/controllers/campaign/getCampaignById';
import { AddIcon, DeleteIcon, EditIcon } from '@chakra-ui/icons';
import { uploadArtwork } from '../../api/controllers/campaign/uploadArtwork';
import { useQueryClient } from '@tanstack/react-query';
import { useMe } from '../../hooks/useMe';
import Artwork from '../../components/Artwork/Artwork';
import { deleteArtwork } from '../../api/controllers/campaign/deleteArtwork';

export const CampaignDetailPage: FC = () => {
	const { campaignId } = useParams();
	const queryClient = useQueryClient();
	const { isRole } = useMe();
	const toast = useToast();
	const { data, isLoading } = useApiQuery(getCampaignById, ['campaigns', campaignId], {
		variables: { routeParams: { campaignId: campaignId ?? '' } },
	});

	const { mutateAsync: uploadArtworkMutation } = useApiMutation(uploadArtwork, {
		onSuccess: () => {
			queryClient.invalidateQueries({ queryKey: ['campaigns', campaignId] });
		},
	});

	const { mutate: deleteArtworkMutation } = useApiMutation(deleteArtwork, {
		onSuccess: () => {
			toast({
				description: 'Artwork deleted',
				status: 'success',
				position: 'bottom-left',
				isClosable: true,
				duration: 2000,
			});
			queryClient.invalidateQueries({ queryKey: ['campaigns', campaignId] });
		},
		onError: () => {
			toast({
				description: 'Error on delete artwork',
				status: 'error',
				position: 'bottom-left',
				isClosable: true,
				duration: 2000,
			});
		},
	});

	if (isLoading) {
		return <Spinner />;
	}
	
	return (
		<Stack gap={4}>
			<Stack flexDirection={'row'} justifyContent={'space-between'}>
				<Heading size={'xl'}>Camapign detail for {data?.name}</Heading>
				{!isRole.streamer && (
					<ButtonGroup>
						<IconButton as={Link} to={`/edit-campaign/${campaignId}`} icon={<EditIcon />} aria-label={'Edit campaign'} />
						<IconButton icon={<DeleteIcon />} aria-label={'Delete campaign'} />
					</ButtonGroup>
				)}
			</Stack>
			<Text>{data?.description}</Text>
			<Stack flexDirection={'row'} gap={4}>
				<Card flex={2}>
					<CardHeader>
						<Heading size={'lg'}>Artworks</Heading>
					</CardHeader>
					<CardBody>
						<Stack flexDir={'row'} flexWrap={'wrap'} spacing={2}>
							{data?.artworks.map((artwork) => (
								<Card key={artwork.id} flex={'1 1 calc(50% - 16px)'} maxW="50%">
									<CardBody>
										<Artwork url={artwork.url} type={artwork.type} />
									</CardBody>
									{!isRole.streamer && (
										<CardFooter>
											<ButtonGroup>
												<IconButton
													as={Link}
													to={`/campaigns/${campaignId}/artworks/${artwork.id}`}
													icon={<EditIcon />}
													aria-label={'Edit artwork'}
												/>
												<IconButton
													icon={<DeleteIcon />}
													colorScheme="red"
													aria-label={'Delete artwork'}
													onClick={() => deleteArtworkMutation({
														campaignId: data.id,
														artworkId: artwork.id,
													})}
												/>
											</ButtonGroup>
										</CardFooter>
									)}
								</Card>
							))}
							{!isRole.streamer && (
								<Button
									as={Link}
									to={`/campaigns/${campaignId}/artworks`}
									leftIcon={<AddIcon />}
									variant="outline"
									borderColor="primary"
									w="100%"
								>
									Add artwork
								</Button>
							)}

						</Stack>
					</CardBody>
				</Card>
				<Card flex={1}>
					<CardHeader>
						<Heading size={'lg'}>Limits</Heading>
					</CardHeader>
					<CardBody>
						<Stack>
							<Stack flexDir={'row'}>
								<strong>Total views:</strong>
								<Text>{data?.limits.totalViews}</Text>
							</Stack>
							<Stack flexDir={'row'}>
								<strong>Views per day:</strong>
								<Text>{data?.limits.viewsPerDay}</Text>
							</Stack>
							<Stack flexDir={'row'}>
								<strong>Views per streamer:</strong>
								<Text>{data?.limits.viewsPerStreamer}</Text>
							</Stack>
						</Stack>
					</CardBody>
					<CardHeader>
						<Heading size={'lg'}>Pricing</Heading>
					</CardHeader>
					<CardBody>
						<Stack>
							<Stack flexDir={'row'}>
								<strong>CPT (Cost per Thousand):</strong>
								<Text>{data?.cpt}</Text>
							</Stack>
						</Stack>
					</CardBody>
				</Card>
			</Stack>
		</Stack>
	);
};
