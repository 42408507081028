import { FC } from "react";
import { StepsLayout, StepsLayoutProps } from "./StepLayout";
import { Flex, List, ListItem, Text } from "@chakra-ui/react";
import { StreamerLink } from "../StreamerLink";

type Props = Readonly<{}> & StepsLayoutProps;

export const SecondStep: FC<Props> = (props) => {
  return (
    <StepsLayout {...props} isLast>
      <List spacing={6}>
        <ListItem>
          <Flex justifyContent={"flex-start"} gap={2} alignItems={"center"}>
            <Text>Copy your StreamIGNITE link</Text>
            <StreamerLink buttonOnly />
          </Flex>
        </ListItem>
        <ListItem>Paste the link to the input field labeled URL.</ListItem>
        <ListItem>
          Set your width and height to match your stream resolution. Usually set
          to 1920 x 1080.
        </ListItem>
        <ListItem>Click Ok</ListItem>
        <ListItem maxW={"50%"}>
          Check the order of sources within the <strong>Sources</strong>{" "}
          section. If your <em>StreamIGNITE</em> source is not on top it won't
          be visible during your stream. You can adjust the order of sources by
          dragging the <em>StreamIGNITE</em> source on top of the list.
        </ListItem>
        <ListItem>
          Add this newly created source to all of your scenes within OBS.
        </ListItem>
      </List>
    </StepsLayout>
  );
};
