import { useToast } from '@chakra-ui/react';
import {
	QueryKey,
	useMutation,
	UseMutationOptions,
	UseMutationResult,
	useQuery,
	useQueryClient,
	UseQueryOptions,
	UseQueryResult,
} from '@tanstack/react-query';
import { useContext, useEffect } from 'react';
import { AuthContext } from './useAuth';

export const useApiQuery = <Q extends (...args: any) => any, E extends Response, V extends Parameters<Q>[0]>(
	query: Q,
	queryKey: QueryKey,
	options?: Omit<UseQueryOptions<Awaited<ReturnType<Q>>, E>, 'queryFn' | 'queryKey'> & { variables?: V },
): UseQueryResult<Awaited<ReturnType<Q>>, E> => {
	const toast = useToast();
	const { setSignedOut } = useContext(AuthContext);
	const queryClient = useQueryClient();
	const apiQuery = useQuery({
		queryKey: [...queryKey],
		queryFn: () => query(options?.variables),
		...options,
	});

	useEffect(() => {
		if (apiQuery.error) {
			toast({
				title: `Error ${apiQuery.error?.status}`,
				description: apiQuery.error.type,
			});
			if (apiQuery.error?.status === 401) {
				setSignedOut();
				queryClient.cancelQueries();
				queryClient.clear();
			}
		}
	}, [apiQuery]);

	return apiQuery;
};

type CustomUseMutationOptions<R, E, V> = Omit<UseMutationOptions<R, E, V>, 'mutationFn'>;

export const useApiMutation = <M extends (...args: any) => any, E extends Response, V extends Parameters<M>[0]>(
	mutation: M,
	options?: CustomUseMutationOptions<Awaited<ReturnType<M>>, E, V>,
): UseMutationResult<Awaited<ReturnType<M>>, E, V> => {
	const toast = useToast();
	const { setSignedOut } = useContext(AuthContext);
	const queryClient = useQueryClient();
	const apiMutation = useMutation({
		mutationFn: mutation,
		...options,
	});

	useEffect(() => {
		if (apiMutation.error) {
			toast({
				title: `Error ${apiMutation.error?.status}`,
				description: apiMutation.error.type,
			});
			if (apiMutation.error?.status === 401) {
				setSignedOut();
				queryClient.cancelQueries();
				queryClient.clear();
			}
		}
	}, [apiMutation]);

	return apiMutation;
};
