import { z } from 'zod';
import { callApi } from '../base';

type Request = Readonly<{
	code: string;
}>;

const response = z.object({
	message: z.string(),
	accessToken: z.string(),
	refreshToken: z.string().optional(),
});

export const authenticateTwitch = async (body: Request): Promise<z.infer<typeof response>> =>
	callApi('/auth/twitch', response, {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json',
		},
		credentials: 'include',
		body: JSON.stringify(body),
	});
