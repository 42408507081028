import { FC } from 'react';
import { ContentBlock } from './ContentBlock';
import { Center, Grid, GridItem, Heading, Stack, Text } from '@chakra-ui/react';

export const Market: FC = () => (
	<ContentBlock>
		<Center backgroundColor={'gray.50'} flexDirection={'column'} p={3} gap={2} borderRadius={12}>
			<Stack gap={2}>
				<Heading size={'lg'} fontWeight={'bold'} textAlign={'center'} color={'gray.800'} py={5}>
					Gaming is bigger market
					<br />
					than the movie & music industry combined
				</Heading>
				<Grid
					gridTemplateColumns={{
						base: '1fr',
						sm: '1fr 1fr',
						lg: 'repeat(4, 1fr)',
					}}
					gap={4}
				>
					<GridItem w={'100%'} h={'100%'}>
						<Stack bgColor={'white'} h={'100%'} borderRadius={8} p={{ base: 4, md: 8, xl: 12 }} justifyContent={'flex-end'}>
							<Text fontSize={'5xl'} fontWeight={700}>
								#1
							</Text>
							<Text color={'gray.600'}>Playing games is number one leisure activity for Czech students</Text>
						</Stack>
					</GridItem>
					<GridItem w={'100%'} h={'100%'}>
						<Stack bgColor={'white'} h={'100%'} borderRadius={8} p={{ base: 4, md: 8, xl: 12 }} justifyContent={'flex-end'}>
							<Text fontSize={'5xl'} fontWeight={700}>
								#3
							</Text>
							<Text color={'gray.600'}>Watching streamers play is number three leisure activity for Czech students</Text>
						</Stack>
					</GridItem>
					<GridItem w={'100%'} h={'100%'}>
						<Stack bgColor={'white'} h={'100%'} borderRadius={8} p={{ base: 4, md: 8, xl: 12 }} justifyContent={'flex-end'}>
							<Text fontSize={'5xl'} fontWeight={700}>
								1.2m
							</Text>
							<Text color={'gray.600'}>Czechs watch gaming streams every month</Text>
						</Stack>
					</GridItem>
					<GridItem w={'100%'} h={'100%'}>
						<Stack bgColor={'white'} h={'100%'} borderRadius={8} p={{ base: 4, md: 8, xl: 12 }} justifyContent={'flex-end'}>
							<Text fontSize={'5xl'} fontWeight={700}>
								200k
							</Text>
							<Text color={'gray.600'}>Czechs watch gaming streams every day</Text>
						</Stack>
					</GridItem>
				</Grid>
			</Stack>
		</Center>
	</ContentBlock>
);
