import { z } from 'zod';
import { callApi } from '../../base';

type Body = Readonly<{
	name: string;
	description?: string | null;
	chatMessage?: string | null;
	limits: {
		totalViews: number;
		viewsPerDay: number;
		viewsPerStreamer: number;
	};
}>;

type RouteParams = Readonly<{
	campaignId: string;
}>;

const response = z.object({
	id: z.string(),
	name: z.string(),
	description: z.string().nullable(),
	chatMessage: z.string().nullable(),
	limits: z.object({
		totalViews: z.number().positive(),
		viewsPerDay: z.number().positive(),
		viewsPerStreamer: z.number().positive(),
	}),
	status: z.string().nullable(),
});

type Request = Readonly<{
	body: Body;
	routeParams: RouteParams;
}>;

export const updateCampaign = async ({ body, routeParams }: Request): Promise<z.infer<typeof response>> =>
	callApi(`/campaigns/${routeParams.campaignId}`, response, {
		method: 'PUT',
		headers: {
			'Content-Type': 'application/json',
		},
		credentials: 'include',
		body: JSON.stringify(body),
	});
