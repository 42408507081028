import { z } from 'zod';
import { callApi } from '../../base';

const response = z.object({
	id: z.string(),
	dimensions: z.object({
		width: z.number().positive(),
		height: z.number().positive(),
		x: z.number(),
		y: z.number(),
	}),
});

type RouteParams = Readonly<{
	campaignId: string;
	artworkId: string;
}>;

type Request = Readonly<{
	routeParams: RouteParams;
	body: {
		dimensions: {
			width: number;
			height: number;
			x: number;
			y: number;
		};
	};
}>;

export const updateArtwork = async ({ routeParams: { campaignId, artworkId }, body }: Request): Promise<z.infer<typeof response>> => {
	return callApi(`/campaigns/${campaignId}/artworks/${artworkId}`, response, {
		method: 'PATCH',
		headers: {
			'Content-Type': 'application/json',
		},
		credentials: 'include',
		body: JSON.stringify(body),
	});
};
