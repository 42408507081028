import { Card, CardHeader, Center, Heading, Stack, Text } from '@chakra-ui/react';
import { FC } from 'react';
import { ContentBlock } from './ContentBlock';

export const Potential: FC = () => (
	<Center w={'100%'} backgroundColor={'gray.50'}>
		<ContentBlock>
			<Stack
				flexDir={{ base: 'column', md: 'row' }}
				gap={{ base: 4, md: 8, xl: 16 }}
				alignItems={'center'}
				justifyContent={'space-between'}
			>
				<Heading size={'lg'} flex={'1 1 auto'}>
					There are lot of Czech Gaming Streamers with untapped potential
				</Heading>
				<Stack gap={4} w={'100%'} maxW={{ md: '50%' }}>
					<Stack p={{ base: 4, md: 8, xl: 12 }} bgColor={'white'} borderRadius={12}>
						<Text fontSize={'4xl'} fontWeight={700}>
							01
						</Text>
						<Text color={'gray.600'}>Thousands of Czech Gaming streamers stream every month.</Text>
					</Stack>
					<Stack p={{ base: 4, md: 8, xl: 12 }} bgColor={'white'} borderRadius={12}>
						<Text fontSize={'4xl'} fontWeight={700}>
							02
						</Text>
						<Text color={'gray.600'}>They have dedicated fans, who trust them and watch them regularly.</Text>
					</Stack>
					<Stack p={{ base: 4, md: 8, xl: 12 }} bgColor={'white'} borderRadius={12}>
						<Text fontSize={'4xl'} fontWeight={700}>
							03
						</Text>
						<Text color={'gray.600'}>
							Fans interact with the streamers through the stream chat and streamers to messages in real time, which creates strong sense of
							community.
						</Text>
					</Stack>
				</Stack>
			</Stack>
		</ContentBlock>
	</Center>
);
