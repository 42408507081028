import { CheckIcon } from '@chakra-ui/icons';
import { Button, Flex, Textarea, useClipboard } from '@chakra-ui/react';
import { FC } from 'react';
import { useMe } from '../hooks/useMe';

const getBroadcastUrl = (token: string) => {
	const url = new URL(window.location.href);

	url.search = '';
	url.pathname = '/broadcast';
	url.searchParams.append('token', token);

	return url.toString();
};

type Props = Readonly<{
	buttonOnly?: boolean;
}>;

export const StreamerLink: FC<Props> = ({ buttonOnly = false }) => {
	const {me} = useMe();
	const broadcastUrl = getBroadcastUrl(me?.streamOverlay.token ?? 'loading');

	const { onCopy, value, hasCopied } = useClipboard(broadcastUrl);

	return (
		<Flex p={1} justifyContent="center" flexWrap={'wrap'} width={buttonOnly ? 'auto' : '100%'} gap={2}>
			<Textarea
				display={buttonOnly ? 'none' : 'initial'}
				disabled
				onClick={(e) => {
					e.stopPropagation();
					onCopy();
				}}
				placeholder={value}
				value={value}
				border={'1px solid #dbdbdb'}
				_hover={{ border: '1px solid #dbdbdb' }}
			/>
			<Button
				height={7}
				onClick={(e) => {
					e.stopPropagation();
					onCopy();
				}}
			>
				{hasCopied ? <CheckIcon /> : 'Copy link'}
			</Button>
		</Flex>
	);
};
