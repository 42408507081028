import { z } from 'zod';
import { callApi } from '../../base';

const response = z.object({
	message: z.string(),
});

export const deleteCampaign = async (campaignId: string): Promise<z.infer<typeof response>> =>
	callApi(`/campaigns/${campaignId}`, response, {
		method: 'DELETE',
		headers: {
			'Content-Type': 'application/json',
		},
		credentials: 'include',
	});
