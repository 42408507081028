import { CSSProperties, Fragment, useEffect, useState } from 'react';
import { VideoBanner } from './VideoBanner';
import { Link as RouterLink, useSearchParams } from 'react-router-dom';
import { ImageBanner } from './ImageBanner';
import { useSocket } from '../hooks/useSocket';
import { Alert, AlertDescription, AlertIcon, AlertTitle, border, Box, Link, SimpleGrid, Stack, Text, theme } from '@chakra-ui/react';
import { z } from 'zod';

const styles: CSSProperties = {
	border: '1px solid',
	padding: 2,
};

const INTERVAL_TO_MILLISECOND = 1000;
const BANNER_MODE = import.meta.env.VITE_BANNER_MODE ?? 'prod';

const sendArtworkSchema = z.object({
	version: z.literal(1),
	type: z.literal('artwork'),
	action: z.literal('show'),
	resource: z.object({
		artworkId: z.string().uuid(),
		artworkPlayId: z.string().uuid(),
		url: z.string(),
		type: z.enum(['image', 'video']),
		interval: z.number().int().positive(),
		dimensions: z.object({
			width: z.number().int(),
			height: z.number().int(),
			x: z.number().int(),
			y: z.number().int(),
		}),	
	}),
});

type BannerMessage = z.infer<typeof sendArtworkSchema>;

const startedArtworkSchema = z.object({
	version: z.literal(1),
	type: z.literal('artwork'),
	action: z.literal('started'),
	resource: z.object({
		artworkId: z.string().uuid(),
		artworkPlayId: z.string().uuid(),
		clientTime: z.number().int().positive(),
	}),
});

type BannerStartedMessage = z.infer<typeof startedArtworkSchema>;

const completedArtworkSchema = z.object({
	version: z.literal(1),
	type: z.literal('artwork'),
	action: z.literal('completed'),
	resource: z.object({
		artworkId: z.string().uuid(),
		artworkPlayId: z.string().uuid(),
		clientTime: z.number().int().positive(),
	}),
});

type BannerCompletedMessage = z.infer<typeof completedArtworkSchema>;

export const BannerManager = () => {
	const [searchParams] = useSearchParams();
	const [bannerData, setBannerData] = useState<BannerMessage | null>(null);
	const socket = useSocket(searchParams.get('token'));

	useEffect(() => {
		if (socket != null) {
			socket.addEventListener('message', (event: MessageEvent) => {
				try {
					const messageData = sendArtworkSchema.parse(JSON.parse(event.data));
					if (messageData != null && messageData.type === 'artwork' && messageData.action === 'show') {
						setBannerData(messageData);

						// @todo make sure this is called when banner is actually started
						const startedBanner: BannerStartedMessage = {
							version: 1,
							type: 'artwork',
							action: 'started',
							resource: {
								artworkId: messageData.resource.artworkId,
								artworkPlayId: messageData.resource.artworkPlayId,
								clientTime: Date.now(),
							},
						};
						socket.send(JSON.stringify(startedBanner));
					}
				} catch (error) {
					console.error(error);
				}
			});
		}
	}, [searchParams, socket]);

	useEffect(() => {
		if (bannerData?.resource?.interval) {
			const timer = setTimeout(() => {
				const completedBanner: BannerCompletedMessage = {
					version: 1,
					type: 'artwork',
					action: 'completed',
					resource: {
						artworkId: bannerData.resource.artworkId,
						artworkPlayId: bannerData.resource.artworkPlayId,
						clientTime: Date.now(),
					},
				};
				socket?.send(JSON.stringify(completedBanner));

				setBannerData(null);
			}, bannerData.resource.interval * INTERVAL_TO_MILLISECOND);
			return () => clearTimeout(timer);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [bannerData?.resource.interval]);

	// @todo fix me. Quickfix for xsplit
	//if (BANNER_MODE !== 'dev' && !window.obsstudio?.pluginVersion) {
	if (false) {
		return (
			<Stack position={'absolute'} w={'100%'} h={'100%'} left={0} top={0} justifyContent={'center'} alignItems={'center'}>
				<Alert
					status={'error'}
					flexDirection={'column'}
					alignItems={'center'}
					justifyContent={'center'}
					textAlign={'center'}
					height={200}
					gap={2}
					maxW={{ base: '100vw', lg: '70vw' }}
				>
					<AlertIcon />
					<AlertTitle>OBS not detected.</AlertTitle>
					<AlertDescription maxW={'60%'}>
						This URL is valid only in OBS browser. If you are unsure what to do with this link, you can visit the{' '}
						<Link as={RouterLink} to={'/setup-wizard'} textDecoration={'underline'}>
							Setup wizard
						</Link>
						page.
					</AlertDescription>
				</Alert>
				{/* {activeCampaigns?.length} behind this link
				<Stack maxW={{ base: '100vw', lg: '70vw' }}>
					<Text>
						Number of active campaigns for this link: <strong>{activeCampaigns?.length}</strong>
					</Text>
					<SimpleGrid columns={5} backgroundColor={theme.colors.white} borderColor={theme.colors.gray} border={'2px solid'}>
						<Box sx={styles}>
							<Text fontWeight={700}>Name</Text>
						</Box>
						<Box sx={styles}>
							<Text fontWeight={700}>Type</Text>
						</Box>
						<Box sx={styles}>
							<Text fontWeight={700}>ResourceUrl</Text>
						</Box>
						<Box sx={styles}>
							<Text fontWeight={700}>Duration</Text>
						</Box>
						<Box sx={styles}>
							<Text fontWeight={700}>Stream Settings</Text>
						</Box>
						{activeCampaigns?.map((campaign) => (
							<Fragment key={campaign.uuid.S}>
								<Box sx={styles}>
									<Text>{campaign.name.S}</Text>
								</Box>
								<Box sx={styles}>
									<Text>{campaign.artworksDetail[0].type.S}</Text>
								</Box>
								<Box sx={styles}>
									<Text>{campaign.artworksDetail[0].resourceUrl.S}</Text>
								</Box>
								<Box sx={styles}>
									<Text>{campaign.artworksDetail[0].duration.N}s</Text>
								</Box>
								<Box sx={styles}>
									<Text>{JSON.stringify(campaign.settings, null, 2)}</Text>
								</Box>
							</Fragment>
						))}
					</SimpleGrid>
				</Stack>*/}
			</Stack>
		);
	}

	if (!bannerData) {
		return <></>;
	}

	if (bannerData?.resource?.type === 'video' && bannerData?.resource?.url != null) {
		console.log('BannerManagerVideo', bannerData.resource.url);
		return <VideoBanner url={bannerData.resource.url} width={bannerData.resource.dimensions.width} height={bannerData.resource.dimensions.height} x={bannerData.resource.dimensions.x} y={bannerData.resource.dimensions.y} />;
	}

	if (bannerData?.resource?.type === 'image' && bannerData?.resource?.url != null) {
		console.log('BannerManagerImage', bannerData.resource.url);
		return <ImageBanner url={bannerData.resource.url} width={bannerData.resource.dimensions.width} height={bannerData.resource.dimensions.height} x={bannerData.resource.dimensions.x} y={bannerData.resource.dimensions.y} />;
	}

	return <></>;
};
