import { Box, Flex, Heading, SimpleGrid, Text } from '@chakra-ui/react';
import { FC } from 'react';
import { useMe } from '../hooks/useMe';
import { useAuth } from '../hooks/useAuth';

export const ProfilePage: FC = () => {
	const { me } = useMe();
	const auth = useAuth();
	return (
		<Box>
			<Flex flexDir={'column'} height={'100%'} gap={'20px'} p={'50px'}>
				<Heading>Profile page</Heading>

				<Text fontFamily={'monospace'}>{JSON.stringify(me, null, 2)}</Text>
				<Text fontFamily={'monospace'}>isLoggedIn: {JSON.stringify(auth, null, 2)}</Text>

				<Text>Active campaigns UUIDs for current user</Text>
				<SimpleGrid>
					{[].map((campaign) => (
						<Text>{campaign}</Text>
					))}
				</SimpleGrid>
			</Flex>
		</Box>
	);
};
