import { FC } from 'react';
import { ContentBlock } from './ContentBlock';
import { Flex, Grid, GridItem, Image, Link, Stack, Text } from '@chakra-ui/react';

export const Footer: FC = () => (
	<ContentBlock>
		<Grid gridTemplateColumns={{ base: '1fr', md: '2fr 1fr 1fr' }} gap={6}>
			<GridItem>
				<Stack>
					<Flex alignItems={'center'}>
						<Image src="/logo.png" maxW={{ base: '150px', lg: '200px' }} />
					</Flex>
				</Stack>
			</GridItem>
			<GridItem>
				<Stack>
					<Text as={'b'}>Brand</Text>
					<Link href="#">Partners</Link>
					<Link href="#">Streamers</Link>
					<Link href="#">Contact</Link>
				</Stack>
			</GridItem>
			<GridItem>
				<Stack>
					<Text as={'b'}>Legal</Text>
					<Link href="#">Privacy policy</Link>
					<Link href="#">Terms of service</Link>
					<Link href="#">Disclaimer</Link>
				</Stack>
			</GridItem>
			<GridItem gridColumn={'1 / -1'}>
				<Text fontSize={'small'}>&copy; 2024 Stream Ignite, All rights reserved</Text>
			</GridItem>
		</Grid>
	</ContentBlock>
);
