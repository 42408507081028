import { FieldValues, UseFormProps, UseFormReturn } from 'react-hook-form';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useForm } from 'react-hook-form';

type CustomUseFormProps<TFieldValues extends FieldValues = FieldValues, TContext = any> = Omit<
	UseFormProps<TFieldValues, TContext>,
	'resolver'
> & {
	readonly schema: z.Schema<TFieldValues>;
};

const useTypedForm = <
	TFieldValues extends FieldValues = FieldValues,
	TContext = any,
	TTransformedValues extends FieldValues | undefined = undefined,
>(
	props: CustomUseFormProps<TFieldValues, TContext>,
): UseFormReturn<TFieldValues, TContext, TTransformedValues> =>
	useForm({
		resolver: zodResolver(props.schema),
		mode: 'onBlur',
		reValidateMode: 'onBlur',
		...props,
	});

export { useTypedForm };
