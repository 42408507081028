import { Box } from '@chakra-ui/react';
import { BannerProps } from './types';

type VideoBannerProps = {
	type?: string;
} & BannerProps;

export const VideoBanner: React.FC<VideoBannerProps> = ({ url, width, height, type = 'video/mp4', x, y}: VideoBannerProps) => {
	return (
		<Box sx={{ video: { width: width, height: height, position: 'fixed', left: x, top: y } }} >
			<video autoPlay muted width={width} height={height}>
				<source src={url} type={type} />
			</video>
		</Box>
	);
};
