import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { ChakraProvider } from '@chakra-ui/react';
import { theme } from './theme';

import { CookiesProvider } from 'react-cookie';
import { AuthProvider } from './hooks/useAuth';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

export const queryClient = new QueryClient();

const isInStreamingSoftware = Boolean(window.obsstudio?.pluginVersion);

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
	<React.StrictMode>
		<QueryClientProvider client={queryClient}>
			<CookiesProvider>
				<AuthProvider>
					<ChakraProvider theme={theme(isInStreamingSoftware)}>
						<BrowserRouter>
							<App />
						</BrowserRouter>
					</ChakraProvider>
				</AuthProvider>
			</CookiesProvider>
			<ReactQueryDevtools />
		</QueryClientProvider>
	</React.StrictMode>,
);
