const RESPONSE_TYPE = 'code';
const CLIENT_ID = import.meta.env.VITE_TWITCH_CLIENT_ID;
const REDIRECT_URI = import.meta.env.VITE_REDIRECT_URI;
const SCOPE = encodeURIComponent(['user:read:email', 'moderator:manage:announcements', 'moderator:read:chatters', 'user:write:chat'].join(' '));
const STATE = import.meta.env.VITE_TWITCH_STATE ?? 'c3ab8aa609ea11e793ae92361f002671';

export const authorizeUrl = `https://id.twitch.tv/oauth2/authorize?response_type=${RESPONSE_TYPE}&client_id=${CLIENT_ID}&redirect_uri=${REDIRECT_URI}&scope=${SCOPE}&state=${STATE}`;

export const getAuthorizeCode = (url: string) => {
	const parsed = new URL(url);
	const code = parsed.searchParams.get('code');

	if (code == null) {
		return null;
	}
	return code;
};
