import { FC } from "react";
import { StepsLayout, StepsLayoutProps } from "./StepLayout";
import { List, ListItem } from "@chakra-ui/react";
import { AddIcon } from "@chakra-ui/icons";

type Props = Readonly<{}> & StepsLayoutProps;

export const FirstStep: FC<Props> = (props) => {
  return (
    <StepsLayout {...props} isFirst>
      <List spacing={6}>
        <ListItem>Start OBS</ListItem>
        <ListItem>
          Click on the <AddIcon h={"0.75em"} /> button within the{" "}
          <strong>Sources section</strong>
        </ListItem>
        <ListItem>
          Click on option&nbsp;
          <strong>
            <em>Browser</em>
          </strong>
        </ListItem>
        <ListItem>Name the source as StreamIGNITE</ListItem>
      </List>
    </StepsLayout>
  );
};
