import { FC, ReactNode } from 'react';
import { useAuth } from '../hooks/useAuth';
import { Navigate } from 'react-router-dom';

export const GuestGuard: FC<{ children: ReactNode }> = ({ children }) => {
	const { isSignedIn } = useAuth();

	if (isSignedIn) {
		return <Navigate to="/" replace />;
	}

	return children;
};
