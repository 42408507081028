import { callApi } from '../../base';

type Values = Readonly<{
	campaignId: string;
	artworkId: string;
}>;

export const deleteArtwork = (values: Values): Promise<undefined> =>
	callApi(`/campaigns/${values.campaignId}/artworks/${values.artworkId}`, null, {
		method: 'DELETE',
		headers: {
			'Content-Type': 'application/json',
		},
		credentials: 'include',
	});
