import { z } from 'zod';
import { callApi } from '../../base';

const response = z.object({
	user: z.object({
		id: z.string().uuid(),
		name: z.string(),
		email: z.string().email(),
		twitchId: z.string(),
		role: z.enum(['streamer', 'admin', 'campaigner']),
	}),
	streamOverlay: z.object({
		token: z.string().uuid(),
	}),
});

export type Me = z.infer<typeof response>;

export const getMe = async (): Promise<Me> =>
	callApi('/auth/me', response, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
		},
		credentials: 'include',
	});
