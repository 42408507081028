import { FC, MouseEvent, useState } from 'react';
import { Flex, Card, CardBody, Heading, SimpleGrid, Stack, Text, theme, useToast, IconButton, Button } from '@chakra-ui/react';
import { useApiMutation, useApiQuery } from '../../hooks/api-communication';
import { AddIcon, DeleteIcon, EditIcon } from '@chakra-ui/icons';
import { Link, Outlet, useNavigate, useParams } from 'react-router-dom';
import { useMe } from '../../hooks/useMe';
import { deleteCampaign } from '../../api/controllers/campaign/deleteCampaign';
import { subscribeUserCampaign } from '../../api/controllers/campaign/subscribeUserCampaign';
import { unsubscribeUserCampaign } from '../../api/controllers/campaign/unsubscribeUserCampaign';
import { getCampaigns } from '../../api/controllers/campaign/getCampaigns';
import { useQueryClient } from '@tanstack/react-query';

const SubscribedButton = (props: {handler: () => void}) => {
	const [hover, setHover] = useState(false);

	const handler = (e: MouseEvent) => {
		e.stopPropagation();
		props.handler();
	};

	return (
		<Button colorScheme={hover ? 'red' : 'green'} onMouseOver={() => setHover(true)} onMouseLeave={() => setHover(false)} onClick={handler}>
			{hover ? (
				<>
					<DeleteIcon /> &nbsp; Unsubscribe
				</>
			) : 'Subscribed'}
		</Button>
	);
};

export const CampaignsPage: FC = () => {
	const { campaignId } = useParams();
	const navigate = useNavigate();
	const toast = useToast();
	const { me, isRole } = useMe();
	const queryClient = useQueryClient();

	const { mutate: deleteCampaignMutation } = useApiMutation(deleteCampaign, {
		onSuccess: (result) => {
			toast({
				description: result.message,
				status: 'success',
				position: 'bottom-left',
				isClosable: true,
				duration: 2000,
			});
			queryClient.invalidateQueries({ queryKey: ['campaigns'] });
		},
		onError: () => {
			toast({
				description: 'Error on delete campaign',
				status: 'error',
				position: 'bottom-left',
				isClosable: true,
				duration: 2000,
			});
		},
	});

	const { mutate: subscribeUserCampaignMutation } = useApiMutation(subscribeUserCampaign, {
		onSuccess: (result) => {
			toast({
				description: 'Successfully subscribed',
				status: 'success',
				position: 'bottom-left',
				isClosable: true,
				duration: 2000,
			});
			queryClient.invalidateQueries({ queryKey: ['campaigns'] });
		},
		onError: (error) => {
			toast({
				description: 'Error on subscribe',
				status: 'error',
				position: 'bottom-left',
				isClosable: true,
				duration: 2000,
			});
		},
	});

	const { mutate: unsubscribeUserCampaignMutation } = useApiMutation(unsubscribeUserCampaign, {
		onSuccess: () => {
			toast({
				description: 'Successfully unsubscribed',
				status: 'success',
				position: 'bottom-left',
				isClosable: true,
				duration: 2000,
			});
			queryClient.invalidateQueries({ queryKey: ['campaigns'] });
		},
		onError: () => {
			toast({
				description: 'Error on unsubscribe',
				status: 'error',
				position: 'bottom-left',
				isClosable: true,
				duration: 2000,
			});
		},
	});

	const { data: campaigns } = useApiQuery(getCampaigns, ['campaigns', 'get']);

	if (campaignId != null) {
		return <Outlet />;
	}

	const onEdit = (e: MouseEvent, campaignId: string) => {
		e.stopPropagation();
		navigate(`/edit-campaign/${campaignId}`);
	};
	const onDelete = (e: MouseEvent, campaignId: string) => {
		e.stopPropagation();
		deleteCampaignMutation(campaignId);
	};

	const onSubscribe = (e: MouseEvent, campaignId: string) => {
		e.stopPropagation();
		subscribeUserCampaignMutation({
			campaignId,
		});
	};

	return (
		<Stack gap={8}>
			<Flex justify={'space-between'} alignItems={'center'}>
				<Heading>Campaigns</Heading>
				{!isRole.streamer && (
					<IconButton variant={'outline'} as={Link} to={'/new-campaign'} aria-label={'Create new campaign'} icon={<AddIcon />} />
				)}
			</Flex>

			<SimpleGrid gap={4} columns={2}>
				{campaigns?.map((campaign) => {
					return (
						<Card
							key={campaign.id}
							cursor={'pointer'}
							onClick={() => {
								navigate(`/campaigns/${campaign.id}`);
							}}
						>
							<CardBody display={'flex'} flexDirection={'column'} backgroundColor={theme.colors.white} color={theme.colors.black}>
								<Flex justifyContent={'space-between'}>
									<Flex direction={'column'}>
										<Heading fontSize={22}>{campaign.name}</Heading>
										<Text mb={4}>{campaign.description}</Text>
									</Flex>
									{!isRole.streamer && (
										<Flex>
											<IconButton
												onClick={(e) => onEdit(e, campaign.id)}
												border={'none'}
												variant={'outline'}
												aria-label={'Create new campaign'}
												icon={<EditIcon />}
											/>
											<IconButton
												variant={'outline'}
												border={'none'}
												aria-label="delete campaign"
												onClick={(e) => onDelete(e, campaign.id)}
												icon={<DeleteIcon />}
											/>
										</Flex>
									)}
								</Flex>
								{campaign.subscribed ? (
									<SubscribedButton handler={() => unsubscribeUserCampaignMutation({campaignId: campaign.id})} />
								) : (
									<Button onClick={(e) => onSubscribe(e, campaign.id)}>Subscribe</Button>
								)}
							</CardBody>
						</Card>
					);
				})}
			</SimpleGrid>
		</Stack>
	);
};
