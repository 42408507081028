import { Box, Flex, Image, Link } from '@chakra-ui/react';
import { FC } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { SignIn } from './LoginPopver';
import { Link as RouterLink } from 'react-router-dom';

const links = [
	{
		title: 'Campaigns',
		href: '/campaigns',
	},
	{
		title: 'Overlays',
		href: '/overlays',
	},
	{
		title: 'Partners rotation',
		href: '/partners-rotator',
	},
];

export const Navigation: FC = () => {
	const navigate = useNavigate();
	const { pathname } = useLocation();

	if (pathname === '/broadcast') {
		return null;
	}

	return (
		<Box position={'fixed'} zIndex={1} left={0} right={0} py={4} bgColor={'gray.50'} px={{ base: '24px', md: '48px', xl: '80px' }}>
			<Flex
				justifyContent={{ base: 'center', md: 'space-between' }}
				alignItems={'center'}
				w={'100%'}
				maxWidth={1736}
				mx={'auto'}
				gap={4}
				flexDir={{ base: 'column', md: 'row' }}
			>
				<Flex alignItems={'center'} onClick={() => navigate('/campaigns')} order={1}>
					<Image src="/logo.png" maxW={{ base: '150px', lg: '200px' }} />
				</Flex>
				<Flex alignItems={'center'} justifyContent={'center'} width={{ base: '100%', md: 'unset' }} gap={4} order={{ base: 3, md: 2 }}>
					{links.map((link, i) => (
						<Link as={RouterLink} key={i} to={link.href}>
							{link.title}
						</Link>
					))}
				</Flex>

				<Box order={{ base: 2, md: 3 }}>
					<SignIn />
				</Box>
			</Flex>
		</Box>
	);
};
