import { Box, Flex } from '@chakra-ui/react';
import { FC, ReactNode } from 'react';
import { Navigation } from '../Navigation/Navigation';

export const Layout: FC<{ children: ReactNode }> = ({ children }) => {
	return (
		<Flex direction="column" minHeight="100vh">
			<Navigation />
			<Box w={'100%'} mx={'auto'}>
				{children}
			</Box>
		</Flex>
	);
};
