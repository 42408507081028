import { FC } from 'react';
import { ContentBlock } from './ContentBlock';
import { Center, Heading, IconButton, Stack, Text } from '@chakra-ui/react';
import { ArrowBackIcon, ArrowForwardIcon } from '@chakra-ui/icons';

const DUMMY_DATA = [
	{
		title: 'Automated ad serving platform',
		content: [
			'Uses standardized online marketing formats (banners & videos)',
			'Ability to target specific audiences, in specific time periods.',
			'Cost per thousand (CPT), total impressions and other media metrics are clearly calculated beforehand and always delivered.',
			'Easy to cooperate with mutliple streamers at the same time.',
		],
	},
	{
		title: 'Influencer marketing',
		content: [
			'The Stream Ignite ads are a natural part of the gaming streams.',
			'The ads are IMPOSSIBLE TO BLOCK OR SKIP and have 100% view-through-rate.',
			'Ads are not stopping fans from watching the content (like pre-roll ads), which makes them less "annoying".',
			'Streamers can react to the ads in real time and motivate fans to click on the link in chat.',
		],
	},
];

export const Solution: FC = () => (
	<ContentBlock>
		<Stack alignItems={'center'} gap={{ base: 4, md: 8, xl: 12 }}>
			<Heading size={'2xl'} lineHeight={1.3} textAlign={'center'}>
				This is where
				<br />
				Stream ✨ Ignite comes in!
			</Heading>
			<Text color={'gray.600'} maxW={{ md: '50%', xl: '30%' }} textAlign={'center'}>
				Technology solution which combines the advantages of automated ad serving platform and influencer marketing
			</Text>
			<Stack mt={8} gap={3} maxW={'100%'}>
				{DUMMY_DATA.map(({ title, content }, index) => (
					<SliderBlock key={index} title={title} content={content} />
				))}
			</Stack>
		</Stack>
	</ContentBlock>
);

type Props = Readonly<{
	title: string;
	content: string[];
}>;

const SliderBlock: FC<Props> = ({ title, content }) => {
	return (
		<Center backgroundColor={'gray.50'} flexDirection={'column'} p={3} gap={2} borderRadius={12} w={'100%'}>
			<Stack w={'100%'}>
				<Stack p={5} flexDir={'row'} justifyContent={'space-between'} alignItems={'center'}>
					<Heading size={'md'}>{title}</Heading>
					<Stack flexDir={'row'}>
						<IconButton aria-label={'previous slide'}>
							<ArrowBackIcon />
						</IconButton>
						<IconButton aria-label={'next slide'}>
							<ArrowForwardIcon />
						</IconButton>
					</Stack>
				</Stack>
				<Stack gap={4} flexDir={'row'} flexWrap={'nowrap'} maxW={'100%'} overflow={'auto'} sx={{ scrollbarWidth: 'none' }}>
					{content.map((text, index) => (
						<Stack
							w={{ base: '75%', md: '40%', xl: '30%' }}
							flexShrink={0}
							p={{ base: 4, md: 8, xl: 12 }}
							bgColor={'white'}
							borderRadius={8}
							key={index}
						>
							<Text fontSize={'4xl'} fontWeight={700}>
								{`0${index + 1}`}
							</Text>
							<Text color={'gray.600'}>{text}</Text>
						</Stack>
					))}
				</Stack>
			</Stack>
		</Center>
	);
};
