import { z } from 'zod';
import { callApi } from '../../base';

type RouteParams = Readonly<{
	campaignId: string;
}>;

type Request = Readonly<{
	routeParams: RouteParams;
}>;

const response = z.object({
	id: z.string().uuid(),
	name: z.string(),
	description: z.string().optional().nullable(),
	limits: z.object({
		totalViews: z.number().positive(),
		viewsPerDay: z.number().positive(),
		viewsPerStreamer: z.number().positive(),
	}),
	cpt: z.number().positive(),
	subscribed: z.boolean(),
	artworks: z.array(
		z.object({
			id: z.string().uuid(),
			type: z.enum(['image', 'video', 'unknown']),
			status: z.enum(['deleted', 'error', 'pedning', 'uploaded']),
			url: z.string(),
		}),
	),
});

export const getCampaignById = async ({ routeParams: { campaignId } }: Request): Promise<z.infer<typeof response>> => {
	return callApi(`/campaigns/${campaignId}`, response, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
		},
		credentials: 'include',
	});
};
