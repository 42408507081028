import { z } from 'zod';
import { callApi } from '../../base';

const response = z.array(
	z.object({
		id: z.string().uuid(),
		name: z.string(),
		description: z.string().optional().nullable(),
		chatMessage: z.string().optional().nullable(),
		limits: z.object({
			totalViews: z.number().positive(),
			viewsPerDay: z.number().positive(),
			viewsPerStreamer: z.number().positive(),
		}),
		cpt: z.number().positive(),
		subscribed: z.boolean(),
		artworks: z.array(
			z.object({
				id: z.string().uuid(),
				type: z.enum(['image', 'video', 'unknown']),
				status: z.enum(['deleted', 'error', 'pedning', 'uploaded']),
				url: z.string(),
			}),
		),
	}),
);

export const getCampaigns = async (): Promise<z.infer<typeof response>> =>
	callApi('/campaigns', response, {
		method: 'GET',
		headers: {
			'Content-Type': 'application/json',
		},
		credentials: 'include',
	});
