import {
  Box,
  Heading,
  Stack,
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  Stepper,
  StepSeparator,
  StepStatus,
  StepTitle,
  useSteps,
} from "@chakra-ui/react";
import { FC } from "react";
import { FirstStep } from "../components/SetupWizard/FirstStep";
import { SecondStep } from "../components/SetupWizard/SecondStep";
import { useNavigate } from "react-router-dom";

const steps = [
  { title: "Get OBS ready", description: "Prepare OBS to add new source" },
  {
    title: "Add your link",
    description: "Connect your OBS browser to StreamIGNITE",
  },
];

export const SetupWizardPage: FC = () => {
  const navigate = useNavigate();
  const { activeStep, setActiveStep } = useSteps({
    index: 0,
    count: steps.length,
  });

  return (
    <Stack mt={8} gap={8}>
      <Heading as={"h1"}>How to setup</Heading>
      <Stepper size="sm" index={activeStep} gap="0">
        {steps.map((step, index) => (
          <Step key={index}>
            <StepIndicator>
              <StepStatus complete={<StepIcon />} />
            </StepIndicator>

            <Box flexShrink="0">
              <StepTitle>{step.title}</StepTitle>
              <StepDescription>{step.description}</StepDescription>
            </Box>

            <StepSeparator />
          </Step>
        ))}
      </Stepper>
      <FirstStep
        showStep={activeStep === 0}
        handleNextClick={() => setActiveStep(1)}
      />
      <SecondStep
        showStep={activeStep === 1}
        handlePreviousClick={() => setActiveStep(0)}
        handleNextClick={() => navigate("/")}
      />
    </Stack>
  );
};
