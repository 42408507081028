import { Box, Flex, Heading, Link, Text } from '@chakra-ui/react';
import { FC } from 'react';
import { SignInButton } from '../components/SignInButton';

export const SignInPage: FC = () => {
	return (
		<Box>
			<Flex direction="column" minHeight="100vh" justifyContent={'center'} alignItems={'center'}>
				<Flex
					bgColor={'#fff'}
					borderRadius={'20px'}
					flexDir={'column'}
					justifyContent={'center'}
					alignItems={'center'}
					boxShadow={'0px 4px 9px 0px rgba(0, 0, 0, 0.117)'}
				>
					<Flex flexDir={'column'} height={'100%'} gap={'20px'} p={'50px'}>
						<Heading>Log in</Heading>
						<Text>Login to your account by using Twitch account</Text>
						<SignInButton />
						<Text textAlign={'center'} mt={'100px'} fontSize={'12px'}>
							Don't have account yet? <Link>Sign up</Link>
						</Text>
					</Flex>
				</Flex>
			</Flex>
		</Box>
	);
};
