type ArtworkType = {
	url: string;
	type: 'image' | 'video' | 'unknown';
	width: number;
	height: number;
}

const Artwork = (props: ArtworkType ) => {
	if (props.type === 'image') {
		return (<picture style={{ width: props.width, height: props.height }}>
			<img src={props.url} style={{ width: '100%', height: '100%', objectFit: 'contain' }} />
		</picture>)
	}

	if (props.type === 'video') {
		return (
			<video autoPlay width={props.width} height={props.height} loop muted>
				<source src={props.url} />
			</video>
		);
	}

	return <div>Unsupported artwork</div>;
};

export default Artwork;
