import { Button, IconButton, Link, Text } from '@chakra-ui/react';
import { FC } from 'react';
import { authorizeUrl } from '../api/twitchAuthorize';
import { ImTwitch } from 'react-icons/im';

export const SignInButton: FC = () => {
	return (
		<Button as={Link} href={authorizeUrl} bgColor={'#8859CE'} color={'#fff'} padding={0} width={'100%'}>
			<IconButton colorScheme="#8859CE" aria-label="Search database" icon={<ImTwitch />} />
			<Text p={'0 10px'}>Log in with Twitch</Text>
		</Button>
	);
};
