import { Button, FormControl, FormErrorMessage, FormHelperText, FormLabel, Heading, Input, Stack, useToast } from '@chakra-ui/react';
import { FC, useEffect, useLayoutEffect } from 'react';
import { z } from 'zod';
import { useTypedForm } from '../../hooks/useTypedForm';
import { useApiMutation, useApiQuery } from '../../hooks/api-communication';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { createCampaign } from '../../api/controllers/campaign/createCampaign';
import { updateCampaign } from '../../api/controllers/campaign/updateCampaign';
import { getCampaigns } from '../../api/controllers/campaign/getCampaigns';
import { useQueryClient } from '@tanstack/react-query';
import { useMe } from '../../hooks/useMe';

const formSchema = z.object({
	name: z.string().min(1),
	description: z.string().optional().nullable(),
	chatMessage: z.string().min(1),
	limits: z.object({
		totalViews: z.number().positive(),
		viewsPerDay: z.number().positive(),
		viewsPerStreamer: z.number().positive(),
	}),
	cpt: z.number().positive(),
});

type FormValues = z.infer<typeof formSchema>;

export const CampaignFormPage: FC = () => {
	const toast = useToast();
	const { pathname } = useLocation();
	const navigate = useNavigate();
	const queryClient = useQueryClient();
	const { campaignId } = useParams();
	const isEditMode = pathname.includes('edit-campaign');
	const { isRole, isLoading: isLoadingMe } = useMe();
	const { data: campaigns, isLoading } = useApiQuery(getCampaigns, ['campaigns', 'get']);
	const campaignToEdit = campaigns?.find((campaign) => campaign.id === campaignId);

	console.log('cam', campaigns);
	useLayoutEffect(() => {
		if (isRole.streamer) {
			navigate('/');
			toast({
				title: 'Permission denied',
				status: 'warning',
				position: 'bottom-left',
				isClosable: true,
				duration: 2000,
			});
		}
	}, [isLoadingMe]);

	const form = useTypedForm({
		schema: formSchema,
	});

	const setDefaultValues = () => {
		const defaultValues = {
			name: campaignToEdit?.name,
			description: campaignToEdit?.description,
			chatMessage: campaignToEdit?.chatMessage ?? '',
			limits: {
				totalViews: campaignToEdit?.limits?.totalViews,
				viewsPerDay: campaignToEdit?.limits?.viewsPerDay,
				viewsPerStreamer: campaignToEdit?.limits?.viewsPerStreamer,
			},
			cpt: campaignToEdit?.cpt,
		};
		form.reset(defaultValues);
	};
	useEffect(() => {
		if (!isLoading) {
			setDefaultValues();
		}
	}, [isLoading]);

	const { mutate: createCampaignMutation } = useApiMutation(createCampaign, {
		onSuccess: (result) => {
			toast({
				description: result.id,
				status: 'success',
				position: 'bottom-left',
				isClosable: true,
				duration: 2000,
			});
			queryClient.invalidateQueries({ queryKey: ['campaigns'] });
			navigate(`/campaigns/${result.id}`);
		},
		onError: () => {
			toast({
				description: 'Error on create campaign',
				status: 'error',
				position: 'bottom-left',
				isClosable: true,
				duration: 2000,
			});
		},
	});

	const { mutate: updateCampaignMutation } = useApiMutation(updateCampaign, {
		onSuccess: (result) => {
			toast({
				description: result.id,
				status: 'success',
				position: 'bottom-left',
				isClosable: true,
				duration: 2000,
			});
			queryClient.invalidateQueries({ queryKey: ['campaigns'] });
			navigate(`/campaigns/${result.id}`);
		},
		onError: () => {
			toast({
				description: 'Error update campaign',
				status: 'error',
				position: 'bottom-left',
				isClosable: true,
				duration: 2000,
			});
		},
	});

	const onSubmit = async (values: FormValues) => {
		const body = { ...values };

		if (body.description === '') {
			body.description = null;
		}

		if (isEditMode) {
			updateCampaignMutation({
				routeParams: { campaignId: campaignId ?? '' },
				body,
			});
		} else {
			createCampaignMutation(body);
		}
	};

	return (
		<Stack backgroundColor={'white'} p={5}>
			<Heading>{isEditMode ? 'Edit campaign' : 'Create new campaign'}</Heading>
			<form
				noValidate
				onSubmit={
					!isRole.streamer
						? form.handleSubmit(onSubmit)
						: () => {
								navigate('/');
								toast({
									title: 'Permission denied',
									status: 'warning',
									position: 'bottom-left',
									isClosable: true,
									duration: 2000,
								});
						  }
				}
			>
				<Stack gap={4} py={8}>
					<FormControl isInvalid={Boolean(form.formState.errors.name?.message)} isRequired>
						<FormLabel>Name</FormLabel>
						<Input variant={'outline'} borderColor={'gray.400'} {...form.register('name')} />
						{form.formState.errors.name?.message && <FormErrorMessage>{form.formState.errors.name?.message}</FormErrorMessage>}
					</FormControl>
					<FormControl isInvalid={Boolean(form.formState.errors.description?.message)}>
						<FormLabel>Description</FormLabel>
						<Input variant={'outline'} borderColor={'gray.400'} {...form.register('description')} />
						<FormHelperText>Optional campaign description</FormHelperText>
					</FormControl>

					<FormControl isInvalid={Boolean(form.formState.errors.chatMessage?.message)} isRequired>
						<FormLabel>Campaign message</FormLabel>
						<Input variant={'outline'} borderColor={'gray.400'} {...form.register('chatMessage')} />
					</FormControl>

					<Heading size={'md'}>Campaign limits</Heading>
					<FormControl isInvalid={Boolean(form.formState.errors.limits?.totalViews?.message)} isRequired>
						<FormLabel>Total views</FormLabel>
						<Input
							variant={'outline'}
							borderColor={'gray.400'}
							type="number"
							{...form.register('limits.totalViews', { valueAsNumber: true })}
						/>
						{form.formState.errors.limits?.totalViews && (
							<FormErrorMessage>{form.formState.errors.limits?.totalViews?.message}</FormErrorMessage>
						)}
					</FormControl>
					<FormControl isInvalid={Boolean(form.formState.errors.limits?.viewsPerDay?.message)} isRequired>
						<FormLabel>Views per day</FormLabel>
						<Input
							variant={'outline'}
							borderColor={'gray.400'}
							type="number"
							{...form.register('limits.viewsPerDay', { valueAsNumber: true })}
						/>
						{form.formState.errors.limits?.viewsPerDay && (
							<FormErrorMessage>{form.formState.errors.limits?.viewsPerDay?.message}</FormErrorMessage>
						)}
					</FormControl>
					<FormControl isInvalid={Boolean(form.formState.errors.limits?.viewsPerStreamer?.message)} isRequired>
						<FormLabel>Views per streamer</FormLabel>
						<Input
							variant={'outline'}
							borderColor={'gray.400'}
							type="number"
							{...form.register('limits.viewsPerStreamer', { valueAsNumber: true })}
						/>
						{form.formState.errors.limits?.viewsPerStreamer && (
							<FormErrorMessage>{form.formState.errors.limits?.viewsPerStreamer?.message}</FormErrorMessage>
						)}
					</FormControl>
					<FormControl isInvalid={Boolean(form.formState.errors.cpt?.message)} isRequired>
						<FormLabel>CPT (Cost per Thousand)</FormLabel>
						<Input
							variant={'outline'}
							borderColor={'gray.400'}
							type="number"
							{...form.register('cpt', { valueAsNumber: true })}
						/>
						{form.formState.errors.cpt && (
							<FormErrorMessage>{form.formState.errors.cpt?.message}</FormErrorMessage>
						)}
					</FormControl>
					<Button type="submit">{isEditMode ? 'Update' : 'Create'}</Button>
				</Stack>
			</form>
		</Stack>
	);
};
