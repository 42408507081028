import { z } from 'zod';

export const baseApiUrl = import.meta.env.VITE_API_URL;

export const callApi = async (url: string | URL | globalThis.Request, validationSchema: z.Schema | null, config?: RequestInit) => {
	const res = await fetch(`${baseApiUrl}${url}`, config);

	if (!res.ok) {
		throw res;
	}

	if (res.status === 204 && validationSchema == null) {
		return;
	}

	const data = await res.json();

	try {
		if (validationSchema == null) {
			return;
		}

		const parsedData = validationSchema.safeParse(data);

		if (parsedData.error) {
			console.warn(`API call ${url} warning. Response schema validation mismatched.`, parsedData.error);
		}

		return data;
	} catch (error) {
		throw new Error('Failed to parse response from the server.');
	}
};
