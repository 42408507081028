import { createContext, FunctionComponent, ReactNode, useContext, useEffect, useState } from 'react';
import { useCookies } from 'react-cookie';
import { getAuthorizeCode } from '../api/twitchAuthorize';
import { useApiMutation } from './api-communication';
import { useQueryClient } from '@tanstack/react-query';
import { authenticateTwitch } from '../api/twitch/authTwitch';

type AuthState =
	| {
			readonly isSignedIn: false;
	  }
	| {
			readonly isSignedIn: true;
			readonly token: string;
	  };

type AuthValue = {
	readonly state: AuthState;
	readonly setSignedOut: () => void;
};

type AuthProviderProps = {
	readonly children: ReactNode;
};

const defaultAuthState: AuthState = {
	isSignedIn: false,
};

const defaultAuthValue: AuthValue = {
	state: defaultAuthState,
	setSignedOut: () => {},
};

const AuthContext = createContext<AuthValue>(defaultAuthValue);

const useAuth = (): AuthState => {
	const { state } = useContext(AuthContext);

	return state;
};

const AuthProvider: FunctionComponent<AuthProviderProps> = ({ children }) => {
	const [{ sessionToken }, setToken, removeToken] = useCookies(['sessionToken']);
	const [state, setState] = useState<AuthState>(sessionToken ? { isSignedIn: true, token: sessionToken } : defaultAuthState);
	const { mutate } = useApiMutation(authenticateTwitch, {
		onSuccess: ({ accessToken }) => {
			setToken('sessionToken', accessToken);
			setState({ isSignedIn: true, token: accessToken });
		},
	});
	const queryClient = useQueryClient();

	useEffect(() => {
		const code = getAuthorizeCode(window.location.href);
		if (!sessionToken && code) {
			mutate({ code });
		}
	}, [mutate, sessionToken]);

	useEffect(() => {
		if (sessionToken) {
			setState({
				isSignedIn: true,
				token: sessionToken,
			});
		} else {
			removeToken('sessionToken');
			setState({
				isSignedIn: false,
			});
		}
	}, [sessionToken, setState, removeToken]);

	return (
		<AuthContext.Provider
			value={{
				state,
				setSignedOut: () => {
					removeToken('sessionToken');
					setState({
						isSignedIn: false,
					});
					queryClient.getQueryCache().clear();
				},
			}}
		>
			{children}
		</AuthContext.Provider>
	);
};

export { useAuth, AuthContext, AuthProvider };
