import { Outlet, RouteObject } from 'react-router-dom';
import { SignInPage } from '../pages/signin';
import { CampaignsPage } from '../pages/Campaign/campaigns';
import { OverlaysPage } from '../pages/overlays';
import { PartnerRotatorPage } from '../pages/partners-rotator';

import { BannerManager } from '../banner/BannerManager';

import { AuthGuard } from '../components/AuthGuard';
import { Layout } from '../components/Layout/Layout';
import { ProfilePage } from '../pages/profile';
import { SetupWizardPage } from '../pages/setup-wizard';
import { CampaignFormPage } from '../pages/Campaign/edit-campaign';
import { GuestGuard } from '../components/GuestGuard';
import { CampaignDetailPage } from '../pages/Campaign/CampaignDetailPage';
import { ArtworkFormPage } from '../pages/Artwork/artwork-form';
import { Homepage } from '../pages/Homepage';

export const mainRoutes: RouteObject = {
	path: '/',
	element: (
		<Layout>
			<Outlet />
		</Layout>
	),
	children: [
		{
			path: '',
			element: <Homepage />,
		},
		{
			path: '/signin',
			element: (
				<GuestGuard>
					<SignInPage />
				</GuestGuard>
			),
		},
		{
			path: '/profile',
			element: (
				<AuthGuard>
					<ProfilePage />
				</AuthGuard>
			),
		},
		{
			path: '/campaigns',
			element: (
				<AuthGuard>
					<CampaignsPage />
				</AuthGuard>
			),
			children: [
				{
					path: ':campaignId',
					element: (
						<AuthGuard>
							<CampaignDetailPage />
						</AuthGuard>
					),
				},
				{
					path: ':campaignId/artworks',
					element: (
						<AuthGuard>
							<ArtworkFormPage />
						</AuthGuard>
					),
				},
				{
					path: ':campaignId/artworks/:artworkId',
					element: (
						<AuthGuard>
							<ArtworkFormPage />
						</AuthGuard>
					),
				},
			],
		},

		{
			path: '/new-campaign',
			element: (
				<AuthGuard>
					<CampaignFormPage />
				</AuthGuard>
			),
		},
		{
			path: '/edit-campaign/:campaignId',
			element: (
				<AuthGuard>
					<CampaignFormPage />
				</AuthGuard>
			),
		},
		{
			path: '/overlays',
			element: (
				<AuthGuard>
					<OverlaysPage />
				</AuthGuard>
			),
		},
		{
			path: '/partners-rotator',
			element: (
				<AuthGuard>
					<PartnerRotatorPage />
				</AuthGuard>
			),
		},
		{
			path: '/broadcast',
			element: <BannerManager />,
		},
		{
			path: '/setup-wizard',
			element: (
				<AuthGuard>
					<SetupWizardPage />
				</AuthGuard>
			),
		},
	],
};
