import { ChevronDownIcon, ChevronUpIcon, InfoIcon, MoonIcon } from '@chakra-ui/icons';
import {
	Avatar,
	Box,
	Flex,
	Link,
	Popover,
	PopoverContent,
	PopoverTrigger,
	SkeletonCircle,
	SkeletonText,
	Text,
	useDisclosure,
} from '@chakra-ui/react';
import { FC, ReactNode, useContext } from 'react';
import { useMe } from '../../hooks/useMe';
import { AuthContext } from '../../hooks/useAuth';
import { SignInButton } from '../SignInButton';
import { StreamerLink } from '../StreamerLink';
import { useNavigate } from 'react-router-dom';

const LoginPopover: FC<{
	children: ReactNode;
	content: ReactNode;
	onCloseLogin: () => void;
	isOpenLogin: boolean;
}> = ({ children, onCloseLogin, isOpenLogin, content }) => {
	return (
		<Popover returnFocusOnClose={false} isOpen={isOpenLogin} onClose={onCloseLogin} placement="bottom-end" closeOnBlur={false}>
			<PopoverTrigger>{children}</PopoverTrigger>
			<PopoverContent
				border={'none'}
				borderRadius={'10px'}
				overflow={'hidden'}
				outline={'none'}
				maxW={500}
				boxShadow={'0px 4px 9px 0px rgba(0, 0, 0, 0.117)'}
				marginRight={'10px'}
			>
				{content}
			</PopoverContent>
		</Popover>
	);
};

const ProfileMenu: FC = () => {
	const navigate = useNavigate();
	const { setSignedOut } = useContext(AuthContext);

	return (
		<Flex padding={3} flexDir={'column'} alignItems={'flex-start'} bgColor={'#fff'}>
			<Box padding={3}>
				<Text>Your link</Text>
				<StreamerLink />
			</Box>

			<Link onClick={() => navigate('/profile')} padding={3} w={'100%'} fontWeight={400} fontSize={'14px'}>
				<Flex alignItems={'center'} gap={'5px'}>
					<InfoIcon />
					Profile
				</Flex>
			</Link>
			<Link onClick={() => navigate('/setup-wizard')} padding={3} w={'100%'} fontWeight={400} fontSize={'14px'}>
				<Flex alignItems={'center'} gap={'5px'}>
					<MoonIcon />
					Setup wizard
				</Flex>
			</Link>
			<Link onClick={setSignedOut} padding={3} w={'100%'} fontWeight={400} fontSize={'14px'}>
				<Flex alignItems={'center'} gap={'5px'}>
					<InfoIcon />
					Logout
				</Flex>
			</Link>
		</Flex>
	);
};

const SignIn: FC = () => {
	const { isOpen, onToggle, onClose } = useDisclosure();
	const { me, isLoading } = useMe();

	if (isLoading) {
		return (
			<Flex alignItems={'center'} gap={2}>
				<SkeletonCircle width={35} height={35} />
				<SkeletonText width={75} height={30} />
			</Flex>
		);
	}

	return me ? (
		<Flex justifyContent={'center'} alignItems={'center'} onClick={onToggle}>
			<LoginPopover isOpenLogin={isOpen} onCloseLogin={onClose} content={<ProfileMenu />}>
				<Flex alignItems={'center'} cursor={'pointer'} gap={2}>
					<Avatar
						src="https://e7.pngegg.com/pngimages/799/987/png-clipart-computer-icons-avatar-icon-design-avatar-heroes-computer-wallpaper.png"
						w={35}
						h={35}
					/>
					<Text>{me?.user.name}</Text>
					{isOpen ? <ChevronUpIcon /> : <ChevronDownIcon />}
				</Flex>
			</LoginPopover>
		</Flex>
	) : (
		<Flex justifyContent={'center'} alignItems={'center'}>
			<SignInButton />
		</Flex>
	);
};

export { SignIn };
