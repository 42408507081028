import { Button, Card, CardBody, CardFooter } from "@chakra-ui/react";
import { FC, ReactNode } from "react";

export type StepsLayoutProps = Readonly<{
  showStep: boolean;
  isFirst?: boolean;
  isLast?: boolean;
  handlePreviousClick?: () => void;
  handleNextClick: () => void;
}>;

export const StepsLayout: FC<
  StepsLayoutProps & Readonly<{ children: ReactNode }>
> = ({
  showStep,
  handlePreviousClick,
  handleNextClick,
  isFirst = false,
  isLast = false,
  children,
}) => {
  return (
    <Card display={showStep ? "block" : "none"}>
      <CardBody>{children}</CardBody>
      <CardFooter justify={"space-between"}>
        {!isFirst && <Button onClick={handlePreviousClick}>Previous</Button>}
        <Button onClick={handleNextClick} ml={"auto"}>
          {isLast ? "Finish" : "Next"}
        </Button>
      </CardFooter>
    </Card>
  );
};
