import { FC } from 'react';
import { ContentBlock } from './ContentBlock';
import { Box, Divider, Heading, Stack, StackDivider, Text } from '@chakra-ui/react';

const DUMMY_DATA = [
	{
		title: 'There are lot of gamers in Czech Republic',
		stats: [
			{
				title: '3.3m+',
				description: 'gamers in Czech Republic aged 15+',
			},
			{
				title: '80%',
				description: 'of Czech generation Z play games regularly',
			},
			{
				title: '32 years',
				description: 'the average age of Czech gamer',
			},
		],
	},
	{
		title: 'But Czech gamers are hard to reach',
		stats: [
			{
				title: '68%',
				description: 'use Ad blocking software, means they NEVER see ANY online ads',
			},
			{
				title: '61%',
				description: 'do not even own a TV',
			},
		],
	},
];

export const Claims: FC = () => (
	<ContentBlock>
		<Stack>
			<Claim {...DUMMY_DATA[0]} />
			<Box py={{ base: 4, md: 8, xl: 10 }}>
				<Divider orientation={'horizontal'} />
			</Box>
			<Claim {...DUMMY_DATA[1]} />
		</Stack>
	</ContentBlock>
);

type Props = Readonly<{
	title: string;
	stats: Readonly<{ title: string; description: string }>[];
}>;

const Claim: FC<Props> = ({ title, stats }) => (
	<Stack flexDirection={{ base: 'column', sm: 'row' }} gap={4} justifyContent={'space-between'}>
		<Heading size={'md'} maxW={250}>
			{title}
		</Heading>
		<Stack
			flexDir={{ base: 'column', lg: 'row' }}
			gap={{ base: 4, md: 8, xl: 12 }}
			alignItems={'flex-start'}
			ml={'auto'}
			w={{ base: '100%', sm: '50%', lg: '70%', xl: '60%', '2xl': '50%' }}
			justifyContent={'flex-start'}
		>
			{stats.map(({ title, description }, index) => (
				<Stack key={index} w={200} flexShrink={0}>
					<Text fontSize={'4xl'} fontWeight={700} whiteSpace={'nowrap'}>
						{title}
					</Text>
					<Text color={'gray.600'}>{description}</Text>
				</Stack>
			))}
		</Stack>
	</Stack>
);
